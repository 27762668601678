
.side-bar {
    display: flex;
    flex-direction: column;
    width: 20vw;
    height: 100vh;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1), 0 2px 5px rgba(0,0,0,0.2);
    background-color: #eeeeee;
    color: #000000;
    overflow-y: scroll;
  }
  
  .side-bar-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    color: whitesmoke;
    text-align: center;
  }
 
  .side-bar-body {
    text-align: center;
    font-family: 'Lato', sans-serif;
  }

  .side-bar-body h3 {
    font-size: 16pt;
    margin-bottom: 10px; 
  }
  
  ul.submenu-items{
    list-style-type: none;
    padding: 0px;
  }

  .submenu-item {
    padding:6px;
    margin:20x;
    color:#555555;
    cursor: pointer;
  }

  .api-link {
    padding:6px;
    margin:20x;
    color:#555555;
    cursor: pointer;
    transition: all .1s ease;
  }
  
  .api-link:hover:hover,.submenu-item:hover{
    cursor: pointer;
    border-left: 5px solid #61AFFE;
    transition: all .1s ease;
    background-color: #e0dbdb;
    color:#000000;
  }
  
  #api-data {
    width: 90vw;
    padding-left: 30px;
    padding-top: 30px;
    overflow-y: scroll;
  }